<template>
  <section class="admin-delivery-address">
    <v-container class="custom-container">
      <div class="textbox">
        <div class="left">
          <h1>{{ $t('langkey.extend_delivery_address') }}</h1>
          <p class="description">
            {{ $t('langkey.extend_delivery_address_2') }}
          </p>
        </div>
        <div class="right">
          <v-btn
            plain
            class="add-delivery-address__button"
            @click="createNewAddress"
          >
            <icon-add-two />
          </v-btn>
        </div>
      </div>

      <v-card
        rounded="sm"
        class="delivery-address"
      >
        <v-card-title class="card__title">
          <v-btn
            depressed
            plain
            class="delete-all__button icon"
            @click="onDeleteAll"
          >
            {{ $t('langkey.delete-all') }} <icon-bin />
          </v-btn>
        </v-card-title>
        <v-data-table
          class="primary-style"
          :loading="isLoading"
          :headers="tableFieldDeliveryAddress"
          :items="userAddresses"
          hide-default-footer
          disable-sort
        >
          <template
            v-for="(field, index) in tableFieldDeliveryAddress"
            v-slot:[`header.${field.value}`]="{ header }"
          >
            <span :key="`${field.value}${index}`">{{ $t(header.text) }}</span>
          </template>
          <template v-slot:[`item.default`]="{ value }">
            <span v-if="value">x</span>
          </template>
          <template v-slot:[`item.function`]="{ item }">
            <div class="d-flex align-center">
              <v-btn
                icon
                plain
                @click.prevent="onEditAddress(item)"
              >
                <icon-edit size="17" />
              </v-btn>
              <v-btn
                icon
                plain
                @click.prevent="onDeleteAddress(item)"
              >
                <icon-bin />
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-dialog
      v-model="createNewAddressDialog"
      max-width="652"
    >
      <v-card class="dialog create-new-address">
        <v-card-title>
          <h5
            v-if="!isEdit"
            class="text"
          >
            {{ $t('langkey.adding_new_delivery_adress') }}
          </h5>
          <h5
            v-else
            class="text"
          >
            {{ $t('langkey.adjust_delivery_address') }}
          </h5>
          <v-btn
            icon
            plain
            @click="onCloseDialog"
          >
            <icon-close size="18" />
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.extended_delivery_adress_name') }} :
            </p>
            <v-text-field
              v-model="inputAddressData.name.value"
              :error-messages="[errorMessageInputAddressData.name]"
              :label="`${$t('langkey.enter-name')}`"
              solo
              :disabled="isDefaultDeliveryAddress"
              @input="errorMessageInputAddressData.name = ''"
            />
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.extended_delivery_adress_street') }} :
            </p>
            <v-text-field
              v-model="inputAddressData.street.value"
              :error-messages="[errorMessageInputAddressData.street]"
              :label="`${$t('langkey.enter-street-name')}`"
              solo
              :disabled="isDefaultDeliveryAddress"
              @input="errorMessageInputAddressData.street = ''"
            />
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.extended_delivery_adress_number') }} :
            </p>
            <v-text-field
              v-model="inputAddressData.streetNumber.value"
              :error-messages="[errorMessageInputAddressData.streetNumber]"
              :label="`${$t('langkey.enter-street-number')}`"
              solo
              :disabled="isDefaultDeliveryAddress"
              @input="errorMessageInputAddressData.streetNumber = ''"
            />
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.extended_delivery_adress_code') }} :
            </p>
            <v-text-field
              v-model="inputAddressData.zip.value"
              :error-messages="[errorMessageInputAddressData.zip]"
              type="number"
              :label="`${$t('langkey.enter-zip')}`"
              solo
              :disabled="isDefaultDeliveryAddress"
              @input="errorMessageInputAddressData.zip = ''"
            />
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.extended_delivery_adress_city') }} :
            </p>
            <v-text-field
              v-model="inputAddressData.city.value"
              :error-messages="[errorMessageInputAddressData.city]"
              :label="`${$t('langkey.enter-city-name')}`"
              solo
              :disabled="isDefaultDeliveryAddress"
              @input="errorMessageInputAddressData.city = ''"
            />
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.extended_delivery_adress_country') }} :
            </p>
            <v-select
              v-model="inputAddressData.countryCode.value"
              :error-messages="[errorMessageInputAddressData.countryCode]"
              :items="countryOptions"
              item-text="label"
              item-value="value"
              solo
              :disabled="isDefaultDeliveryAddress"
              @input="errorMessageInputAddressData.countryCode = ''"
            >
              <template #append>
                <icon-caret />
              </template>
            </v-select>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            outlined
            plain
            @click="onCloseDialog"
          >
            {{ $t('langkey.cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="isDefaultDeliveryAddress"
            @click="onSaveAddress"
          >
            {{ $t('langkey.save-address') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      key="deleteAddressDialog"
      v-model="deleteAddressDialog"
      max-width="330"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('langkey.delete-delivery-address-subject') }}?
        </v-card-title>
        <v-card-text>{{ $t('langkey.delete-delivery-address-message') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="deleteAddressDialog = false"
          >
            {{ $t('langkey.cart-clear-cancel') }}
          </v-btn>
          <v-btn
            color="error"
            text
            @click="deleteItem"
          >
            {{ $t('langkey.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      key="deleteAllAddressDialog"
      v-model="deleteAllAddressDialog"
      max-width="330"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('langkey.delete-all-address') }}?
        </v-card-title>
        <v-card-text>{{ $t('langkey.delete-all-address-confirm') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="deleteAllAddressDialog = false"
          >
            {{ $t('langkey.cart-clear-cancel') }}
          </v-btn>
          <v-btn
            color="error"
            text
            @click="deleteAll"
          >
            {{ $t('langkey.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<style lang="scss">
@import "@/styles/pages/admin-delivery-address.scss";
</style>

<script>
import IconAddTwo from '@/components/icons/IconAddTwo';
import IconBin from '@/components/icons/IconBin';
import IconEdit from '@/components/icons/IconEdit';
import IconCaret from '@/components/icons/IconCaret';
import allCountries from '@/constants/countries';
import { tableFieldDeliveryAddress } from './configs.js';
import { mapState } from 'vuex';

export default {
  components: {
    IconAddTwo,
    IconBin,
    IconEdit,
    IconCaret,
  },
  data() {
    return {
      createNewAddressDialog: false,
      deleteAllAddressDialog: false,
      deleteAddressDialog: false,
      inputAddressData: {
        name: {
          required: true,
          value: '',
        },
        street: {
          required: true,
          value: '',
        },
        streetNumber: {
          required: true,
          value: '',
        },
        zip: {
          required: true,
          value: '',
        },
        city: {
          required: true,
          value: '',
        },
        countryCode: {
          required: false,
          value: 'DE',
        },
      },
      errorMessageInputAddressData: {
        name: '',
        street: '',
        streetNumber: '',
        zip: '',
        city: '',
        countryCode: '',
      },
      isLoading: false,
      editId: '',
      deleteId: '',
      isDefaultDeliveryAddress: false,
    };
  },
  computed: {
    ...mapState('user', [
      'userAddresses',
      'userInformation',
    ]),
    tableFieldDeliveryAddress() {
      return tableFieldDeliveryAddress;
    },
    hasErrorInputAddress() {
      return Object.values(this.errorMessageInputAddressData).some((val) => val !== '');
    },
    countryOptions() {
      return ['DE'];
    },
    isEdit() {
      return this.editId.length !== 0;
    },
  },
  watch: {
    createNewAddressDialog(val) {
      if (!val) {
        this.editId = '';
        this.isDefaultDeliveryAddress = false;
      }
    },
  },
  created() {
    this.isLoading = true;
    this.$store.dispatch('user/getUserAdresses')
      .finally(() => {
        this.isLoading = false;
      });
    if (this.userInformation === null) {
      this.$store.dispatch('user/getUserInformation');
    }
  },
  methods: {
    createNewAddress() {
      this.inputAddressData = {
        name: {
          required: true,
          value: '',
        },
        street: {
          required: true,
          value: '',
        },
        streetNumber: {
          required: true,
          value: '',
        },
        zip: {
          required: true,
          value: '',
        },
        city: {
          required: true,
          value: '',
        },
        countryCode: {
          required: false,
          value: 'DE',
        },
      };
      this.createNewAddressDialog = true;
    },
    onSaveAddress() {
      this.validateInput();
      if (this.hasErrorInputAddress) {
        return;
      }
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      if (!this.isEdit) {
        let payload = {};
        Object.keys(this.inputAddressData).forEach((key) => {
          payload = {
            ...payload,
            [key]: this.inputAddressData[key].value,
          };
        });
        this.$store.dispatch('user/addUserAddress', payload)
          .then(() => {
            this.createNewAddressDialog = false;
          })
          .finally(() => {
            this.$store.dispatch('dialog/setLoadingDialog', {
              status: false,
            });
          });
      } else {
        let payload = {};
        Object.keys(this.inputAddressData).forEach((key) => {
          payload = {
            ...payload,
            [key]: this.inputAddressData[key].value,
          };
        });
        this.$store.dispatch('user/editUserAddress', {
          ...payload,
          id: this.editId,
        })
          .then(() => {
            this.createNewAddressDialog = false;
            this.editId = '';
            this.isDefaultDeliveryAddress = false;
          })
          .finally(() => {
            this.$store.dispatch('dialog/setLoadingDialog', {
              status: false,
            });
          });
      }
    },
    validateInput () {
      Object.keys(this.inputAddressData).forEach((key) => {
        if (this.inputAddressData[key].required && !this.inputAddressData[key].value) {
          this.errorMessageInputAddressData = {
            ...this.errorMessageInputAddressData,
            [key]: this.$t('langkey.mandatory-entry-fields'),
          };
        }
      });
    },
    deleteItem() {
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      this.$store.dispatch('user/deleteUserAddress', this.deleteId)
        .then(() => {
          this.deleteId = '';
          this.deleteAddressDialog = false;
        })
        .finally(() => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
        });
    },
    deleteAll() {
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      this.$store.dispatch('user/deleteAllUserAddress')
        .then(() => {
          this.deleteAllAddressDialog = false;
        })
        .finally(() => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
        });
    },
    onEditAddress(item) {
      this.editId = item.id;
      this.inputAddressData = {
        name: {
          required: true,
          value: item.name,
        },
        street: {
          required: true,
          value: item.street,
        },
        streetNumber: {
          required: true,
          value: item.streetNumber,
        },
        zip: {
          required: true,
          value: item.zip,
        },
        city: {
          required: true,
          value: item.city,
        },
        countryCode: {
          required: false,
          value: item.countryCode,
        },
      };
      this.isDefaultDeliveryAddress = item.default;
      this.createNewAddressDialog = true;
    },
    onDeleteAddress(item) {
      this.deleteId = item.id;
      this.deleteAddressDialog = true;
    },
    onDeleteAll() {
      if (this.userAddresses.length > 0) {
        this.deleteAllAddressDialog = true;
      }
    },
    onCloseDialog() {
      this.editId = '';
      this.isDefaultDeliveryAddress = false;
      this.createNewAddressDialog = false;
    },
  },
};
</script>