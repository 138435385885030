<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :height="size"
    viewBox="0 0 19 19.829"
  >
    <g
      id="Component_111_7"
      data-name="Component 111 – 7"
      transform="translate(1 1)"
    >
      <path
        id="Path_5293"
        data-name="Path 5293"
        d="M4,20H8L18.5,9.5a2.828,2.828,0,0,0-4-4L4,16v4"
        transform="translate(-3.5 -4.672)"
        fill="none"
        stroke="#000000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <line
        id="Line_90"
        data-name="Line 90"
        x2="17"
        transform="translate(0 17.829)"
        fill="none"
        stroke="#000000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: '19',
    },
  },
};
</script>